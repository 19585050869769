








import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class LoadingView extends Vue {
  loading: boolean = false;

  start () {
    this.loading = true;
  }

  finish () {
    this.loading = false;
  }
}
