import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import BlogStore from './BlogStore';
import NewsStore from './NewsStore';

let blogStore: BlogStore;
let newsStore: NewsStore;

const initializer = (store: Store<any>) => {
  blogStore = getModule(BlogStore, store);
  newsStore = getModule(NewsStore, store);
};

export const plugins = [initializer];

export {
  initializer,
  blogStore,
  newsStore
};