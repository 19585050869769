import { AxiosInstance } from 'axios';
import { IBlogArticle } from '~/models';

export interface IBlogApi {
  list(): Promise<IBlogArticle[]>;
}

export class BlogApi implements IBlogApi {
  constructor (public axios: AxiosInstance) {}

  async list (): Promise<IBlogArticle[]> {
    const response = await this.axios.get('GetYouSpeakBlog?code=/CMZzc6sxaqKX0beGgUpMHYuakOdqitkGhY7y7EIzU9tAZ640wJkFQ==');
    return response.data as IBlogArticle[];
  }
}
