<template>
  <v-app style="background-color: #F8F8F5">
    <theHeader />
    <v-main>
      <nuxt />
    </v-main>
    <theFooter />
  </v-app>
</template>

<script>
import TheHeader from '~/components/header';
import TheFooter from '~/components/footer';

export default {
  components: {
    TheHeader,
    TheFooter
  }
};
</script>
