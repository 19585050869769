import { Plugin } from '@nuxt/types';
import { initializeGateway } from '~/api';

const GatewayAccessorPlugin: Plugin = (context) => {
  const axiosForApi = context.$axios.create({
    baseURL: process.env.FORM_MAILER_URL,
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });

  initializeGateway(axiosForApi);
};

export default GatewayAccessorPlugin;