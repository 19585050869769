

























import { Component, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class HeaderPage extends Vue {
  home () {
    this.$router.push('/');
  }
}
