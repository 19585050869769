import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { blogApi } from '~/api';
import { IBlogArticle } from '~/models';

@Module({
  name: 'BlogStore',
  stateFactory: true,
  namespaced: true
})
export default class BlogStore extends VuexModule {
  blogs: IBlogArticle[] = [] as IBlogArticle[];
  page: number = 1;
  itemsPerPage: number = 9;

  current: IBlogArticle = {} as IBlogArticle;
  next: IBlogArticle | null = null;
  previous: IBlogArticle | null = null;

  get pageCount () : number {
    return Math.floor(this.blogs.length / this.itemsPerPage) + 1;
  }

  get pageBlogs (): IBlogArticle[] {
    return this.blogs.slice((this.page - 1) * this.itemsPerPage, (this.page - 1) * this.itemsPerPage + this.itemsPerPage)
  }

  get nextBlogs (): IBlogArticle[] {
    let index = this.blogs.findIndex(x => x.id === this.current.id);

    if (index > this.blogs.length - 4) {
      let blogs = this.blogs.slice(index + 1);
      return blogs.concat(this.blogs.slice(0, 3 - blogs.length));
    } else {
      return this.blogs.slice(index + 1, index + 4);
    }
  }

  @Mutation
  setList (blogs: IBlogArticle[]) {
    this.blogs = blogs;
  }

  @Mutation
  setBlog (payload: { current: IBlogArticle, previous: IBlogArticle | null, next: IBlogArticle | null}) {
    this.current = payload.current;
    this.previous = payload.previous;
    this.next = payload.next;
  }

  @Mutation
  setPage(page: number) {
    this.page = page;
  }

  @Action
  async getBlogs () {
    const list = await blogApi.list();
    if (list) {
      this.setList(list);
    }
  }

  @Action
  async get (payload: { no: number }) {
    if (!this.blogs || this.blogs.length === 0) {
      await this.getBlogs();

      if (!this.blogs)
        throw Error();
    }

    let index = this.blogs.findIndex(x => x.no === payload.no);
    if (index === -1) {
      throw Error();
    }

    this.setBlog({
      current: this.blogs[index],
      previous: index === 0 ? null : this.blogs[index - 1],
      next: index === this.blogs.length - 1 ? null : this.blogs[index + 1]
    });
  }

  @Action
  async getBySlug(payload: { slug: string }) {
    if (!this.blogs || this.blogs.length === 0) {
      await this.getBlogs();

      if (!this.blogs)
        throw Error();
    }

    let index = this.blogs.findIndex(x => x.slug === payload.slug);
    if (index === -1) {
      throw Error();
    }

    this.setBlog({
      current: this.blogs[index],
      previous: index === 0 ? null : this.blogs[index - 1],
      next: index === this.blogs.length - 1 ? null : this.blogs[index + 1]
    });
  }
}