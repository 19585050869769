import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { newsApi } from '~/api';
import { INewsArticle } from '~/models';

@Module({
  name: 'NewsStore',
  stateFactory: true,
  namespaced: true
})
export default class NewsStore extends VuexModule {
  news: INewsArticle[] = [] as INewsArticle[];
  page: number = 1;
  itemsPerPage: number = 9;

  current: INewsArticle = {} as INewsArticle;
  next: INewsArticle | null = null;
  previous: INewsArticle | null = null;

  get pageCount () : number {
    return Math.floor(this.news.length / this.itemsPerPage) + 1;
  }

  get pageBlogs (): INewsArticle[] {
    return this.news.slice((this.page - 1) * this.itemsPerPage, (this.page - 1) * this.itemsPerPage + this.itemsPerPage)
  }

  get latestNews (): INewsArticle[] {
    return this.news.slice(0, 3);
  }

  @Mutation
  setList (news: INewsArticle[]) {
    this.news = news;
  }

  @Mutation
  setNews (payload: { current: INewsArticle, previous: INewsArticle | null, next: INewsArticle | null}) {
    this.current = payload.current;
    this.previous = payload.previous;
    this.next = payload.next;
  }

  @Mutation
  setPage(page: number) {
    this.page = page;
  }

  @Action
  async getNews () {
    const list = await newsApi.list();
    if (list) {
      this.setList(list);
    }
  }

  @Action
  async get (payload: { no: number }) {
    if (!this.news || this.news.length === 0) {
      await this.getNews();

      if (!this.news)
        throw Error();
    }

    let index = this.news.findIndex(x => x.no === payload.no);
    if (index === -1) {
      throw Error();
    }

    this.setNews({
      current: this.news[index],
      previous: index === 0 ? null : this.news[index - 1],
      next: index === this.news.length - 1 ? null : this.news[index + 1]
    });
  }

  @Action
  async getBySlug(payload: { slug: string }) {
    if (!this.news || this.news.length === 0) {
      await this.getNews();

      if (!this.news)
        throw Error();
    }

    let index = this.news.findIndex(x => x.slug === payload.slug);
    if (index === -1) {
      throw Error();
    }

    this.setNews({
      current: this.news[index],
      previous: index === 0 ? null : this.news[index - 1],
      next: index === this.news.length - 1 ? null : this.news[index + 1]
    });
  }
}