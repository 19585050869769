


































import { Component, Vue } from 'nuxt-property-decorator';
import { FreeTrialButton } from '~/components';

@Component({
  components: {
    FreeTrialButton
  }
})
export default class ErrorView extends Vue {}
