

























































import { Component, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class FooterPage extends Vue {
  get path (): string {
    return this.$route.path;
  }

  @Watch('path')
  onPathChanged () {
    this.$data.path = this.$route.path;
  }
}
