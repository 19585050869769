import { IBlogApi, BlogApi } from './BlogApi';
import { INewsApi, NewsApi } from './NewsApi';

let blogApi: IBlogApi;
let newsApi: INewsApi;

export function initializeGateway (axiosForApi) {
  blogApi = new BlogApi(axiosForApi);
  newsApi = new NewsApi(axiosForApi);
}

export {
  blogApi,
  newsApi
};