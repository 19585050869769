import { AxiosInstance } from 'axios';
import { INewsArticle } from '~/models';

export interface INewsApi {
  list(): Promise<INewsArticle[]>;
}

export class NewsApi implements INewsApi {
  constructor (public axios: AxiosInstance) {}

  async list (): Promise<INewsArticle[]> {
    const response = await this.axios.get('GetYouSpeakNews?code=kEYBy9Ubm1sEE5X0xwW68Q/MMwubni8yK5DB9VHsSJA6tYa0NZT4cg==');
    return response.data as INewsArticle[];
  }
}
